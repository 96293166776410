import React from "react";
import tw from "twin.macro";
import "./bounce.css";
import BelowTheFoldDemoTeaser from "./BelowTheFoldDemoTeaser";
const Container = tw.div`pt-24 w-full flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left mr-5 ml-5`;
const Heading = tw.div`font-black text-left text-5xl lg:text-6xl text-center leading-tight tracking-wide`;
const CTA = tw.div`text-center font-normal text-xl`;
const HeadingHighlight = tw.span`text-primary-500`;

const AboveTheFold = (properties) => {
    const { isMobile } = properties;

    return (
        <Container>
            <TextContent>
                <Heading>
                    Next generation<br/> <HeadingHighlight>maker collaboration</HeadingHighlight>.
                </Heading>
                <CTA style={{fontSize: isMobile ? '1rem' : undefined, paddingTop: isMobile ? '2rem': '1rem',
                    marginLeft: isMobile ? '1rem' : '31rem', marginRight: isMobile ? '1rem' : '31rem'}}>
                    Intra-team and direct customer task management with more automation and certainty and less chat and meetings.
                </CTA>
                <BelowTheFoldDemoTeaser isMobile={isMobile} />
            </TextContent>
        </Container>
    );
};

export default AboveTheFold;